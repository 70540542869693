/* ContactForm.css */

form {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 2px; /* Adjust the margin as needed */
}

label {
  display: block;
  /* margin-bottom: 8px; */
  font-weight: bold;
  margin: 2px; /* Adjust the margin as needed */
}

input {
  width: 100%;
  padding: 5px;
  /* margin-bottom: 12px; */
  border: 1px solid #ccccccbc;
  border-radius: 4px;
  margin: 2px; /* Adjust the margin as needed */
}

/* button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  } */

button:hover {
  background-color: #207dbb;
}

textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical; /* Allow vertical resizing */
  margin: 2px; /* Adjust the margin as needed */
  height: 50px;
}

/* Add a class for the word limit */
.word-limit {
  color: #888;
  font-size: 12px;
  margin-top: 6px;
}
