/* Add these styles to your existing CSS or create a new file */

.button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px; /* Adjust padding as needed */
    text-align: center;
    text-decoration: none;
    border-radius: 4px; /* Add border-radius for rounded corners */
    transition: background-color 0.3s ease-in-out; /* Add a smooth transition effect */
  
    /* Additional styles for better visual appearance */
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
  }
  
  .button:hover {
    background-color: #007bb5; /* Change color on hover */
  }
  