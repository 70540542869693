/* Contact.css */

.contact__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px; /* Adjust the gap as needed */
  }
  
  .contact__header {
    display: flex;
    flex-direction: column;
  }
  
  .contact__image img {
    max-width: 100%;
    height: auto;
    margin-bottom: 20px; /* Adjust the margin as needed */
  }
  
  .contact__header-content {
    flex: 1;
  }
  
  .contact__header-content h2 {
    margin-top: 0;
  }
  .contact__form
  {
    align-content: center;
    align-items: center;
  }
  
  /* Add any additional styling as needed */
  