.service-card {
    position: relative;
    overflow: hidden;
    border: 1px solid #ddd;
    border-radius: 8px;
    /* margin: 10px; */
    width: 300px;
    padding: auto;
    transition: box-shadow 0.3s ease-in-out;
  }
  
  .service-card:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }
  
  .service-image {
    /* width: max-content; */
    width: 100%;
    /* align-items: center; */
    padding: 1rem;
    height: 200px; /* Set a fixed height for the image */
    object-fit:contain; 
    /* Ensure the image covers the container */
    border-bottom: 1px solid #ddd;
  }
  
  .service-content {
    padding: .5rem;
    text-align: left; /* Align text to the left */
  }
  
  .service-content h3 {
    margin-top: 0;
    padding-left: 1rem;
  }
  .service-content div{
    margin: .8rem;
  }
  
  .button {
    opacity: 0;
    background-color: #3498db;
    color: #fff;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    border-radius: 0 0 8px 8px;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .service-card:hover .button {
    opacity: 1;
  }
  
  .button:hover {
    background-color: #007bb5;
  }
  
  .card-container {
    /* position: relative;
    height: 200px; */
    overflow: hidden;
    padding: auto;
  }
  
  .title {
    margin-bottom: 10px;
  }
  
  .description {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    /* white-space: nowrap; */
  }
  
  .card-container {
    height: 160px; /* Set a fixed height */
    overflow-y: auto; /* Enable vertical scrolling */
    /* width:fit-content; */
  }

  
.service-card:hover .service-image {
    animation: scaleImage 0.5s ease-in-out;
  }

  
@keyframes scaleImage {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }