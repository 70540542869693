/* Slider.css */

.image-slider {
    width: 100%;
    max-width: 550px; /* Adjust the maximum width as needed */
    margin: 0 auto;
  }
  
  .slider-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px; /* Adjust the height as needed */
  }
  
  .slider-item img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; Ensures the image covers the entire slide */

    object-fit:contain;
    padding: 2rem;
  }
  

  .image-slider .slider-item {
    opacity: 0;
    animation: fadeIn ease-in-out 1;
    animation-fill-mode: forwards;
    animation-duration: 1s;
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }