.service-detail {
    margin-top: 10rem;
  }
  
  .service-detail__header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* .service-detail__header h2 {
    color: #333;
  } */
  
  .service-detail__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .service-detail__image img {
    max-width: 50%;
    height: 40%;
    border-radius: 8px;
    flex: 0 0 48%; 
    margin-left: 5rem;
    align-items: center;
  }
  
  .service-detail__info {

    /* color: #555; */
    font-weight: bold; /* Make the text bold */
    font-style: italic; /* Add italic style */
    font-family: 'Your Preferred Font', sans-serif;
    /* flex: 1; */
    flex: 0 0 48%; /* Adjust the width as needed */
    margin-left: 20px;
    font-size: larger;
    
  }
  
  /* .service-detail__info p {
    color: #555;
  } */
  
  .service-detail__description p {
    /* color: #444; */
    line-height: 1.6;
  }
  