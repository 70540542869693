/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/*===== VARIABLES CSS =====*/
:root {
  --header-height: 3rem;

  /* ========== Colors ==========*/
  --first-color: #427bf7;
  --first-color-alt: #30db77d7;
  --primary-color: hsl(var(--hue), var(--sat), 60%);
  --primary-color-light: hsl(var(--hue), var(--sat), 85%);
  --title-color: #393939;
  --text-color: #707070;
  --text-color-light: #a6a6a6;
  --body-color: rgba(247, 249, 246, 0.565);
  --container-color: #ffffff;

  /*========== Font and typography ==========*/
  --body-font: "Poppins", sans-serif;
  --biggest-font-size: 2.25rem;
  --h1-font-size: 1.5rem;
  --h2-font-size: 1.25rem;
  --h3-font-size: 1rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.813rem;
  --smaller-font-size: 0.75rem;

  /*========== Font weight ==========*/
  --font-medium: 500;
  --font-semi-bold: 600;

  /*========== Margenes ==========*/
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 768px) {
  :root {
    --medium-font-size: 2rem;
    --biggest-font-size: 4rem;
    --h1-font-size: 2.25rem;
    --h2-font-size: 1.5rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
    --smaller-font-size: 0.813rem;
  }
}

/*========== CLASS CSS ==========*/
.section {
  padding: 4rem 0 2rem;
}

.section-title,
.section-subtitle {
  text-align: center;
}

.section-title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-3);
}

.section-subtitle {
  display: block;
  color: var(--first-color);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1);
}

/*========== LAYOUT ==========*/
.bd-container {
  max-width: 960px;
  width: calc(100% - 2rem);
  margin-left: var(--mb-2);
  margin-right: var(--mb-2);
}

.bd-grid {
  display: grid;
  gap: 1.5rem;
}

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--z-fixed);
  background-color: var(--body-color);
}

/*========== NAV ==========*/
.nav {
  max-width: 1024px;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
}


/* .nav {
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
/*=============== BUTTONS ===============*/
.button2 {
  /* background: linear-gradient(to right, #333, #000); Sleek dark gradient */
  /* color: #fff; Ensure readability on the dark background */
  border: none;
  padding: 2px 2px; /* Adjust padding for the desired button size */
  font-size: 14px; /* Match the minimalist theme */
  font-weight: 500; /* Slightly bold for emphasis */
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 5px; /* Fully rounded corners for a modern look */
  transition: all 0.3s ease-in-out; /* Smooth transitions for hover effects */
  box-shadow: 1px 3px 6px; 
  /* Subtle shadow for depth */
  cursor: pointer; /* Indicate interactivity */
  display: inline-block; /* Ensure it remains inline */
  vertical-align: middle; /* Aligns with text */
  margin-left: 3rem ;
}

.button2:hover {
  /* background: linear-gradient(to right, #555, #222); Brighter hover state */
  transform: scale(1.08); /* Slight scaling for emphasis */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5); 
  /* More pronounced shadow on hover */
}

.__button2 {
 background: linear-gradient(to right, #56ccf2, #2f80ed); /* Gradient from light blue to dark blue */
  color: #fff; /* White text */
  border: none; /* Optional: Remove border for a cleaner look */
  padding: 2px 2px; /* Adjust padding for the desired button size */
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 16px; /* Adjust font size */
  border-radius: 3px; /* Optional: Add border-radius for rounded corners */
  transition: background-color 0.3s ease; /* Optional: Add a smooth transition effect */
  /* box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.8); Initial shadow color with transparency */
  margin-left: 3rem ;
  /* font-weight: var(--font-semi-bold);
  transition: .3s; */
}

.__button2:hover {
  background: linear-gradient(to right, #3498db, #2980b9); /* Gradient from blue to dark blue */
  color: #fff; /* White text */
}

.button2__header {
  background: linear-gradient(to right, #3498db, #2980b9); /* Gradient from blue to dark blue */
  color: #fff; /* White text */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Shadow effect */
}

.button2-link {
  background: none;
  padding: 0;
  color: var(--title-color);
}

.button2-link:hover {
  background-color: transparent;
}

.button2-flex {
  display: inline-flex;
  align-items: center;
  column-gap: .25rem;
  padding: .75rem 1rem;
}

.button2__icon {
  font-size: 1.5rem;
}


@media screen and (max-width: 768px) {
  .nav__menu {
    position: fixed;
    top: -100%;
    left: 0;
    width: 100%;
    padding: 1.5rem 0 1rem;
    text-align: center;
    background-color: var(--body-color);
    transition: 0.4s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 1rem 1rem;
    z-index: var(--z-fixed);
  }
}

.nav__item {
  margin-bottom: var(--mb-2);
}

.nav__link,
.nav__logo,
.nav__toggle {
  color: var(--text-color);
  font-weight: var(--font-medium);
}

.nav__logo:hover {
  color: var(--first-color);
}

.nav__link {
  transition: 0.3s;
}

.nav__link:hover {
  color: var(--first-color);
}

.nav__toggle {
  font-size: 1.3rem;
  cursor: pointer;
}

/* Show menu */
.show-menu {
  top: var(--header-height);
}

/* Active menu */
.active-link {
  color: var(--first-color);
}

/* Change background header */
.scroll-header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Scroll top */
.scrolltop {
  position: fixed;
  right: 1rem;
  bottom: -20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  background: rgba(6, 156, 84, 0.5);
  border-radius: 0.4rem;
  z-index: var(--z-tooltip);
  transition: 0.4s;
  visibility: hidden;
}

.scrolltop:hover {
  background-color: var(--first-color-alt);
}

.scrolltop__icon {
  font-size: 1.8rem;
  color: var(--body-color);
}

/* Show scrolltop */
.show-scroll {
  visibility: visible;
  bottom: 1.5rem;
}

/*========== HOME ==========*/
.home__container {
  height: calc(100vh - var(--header-height));
  align-content: center;
}

.home__title {
  font-size: var(--medium-font-size);
  color: var(--first-color);
  margin-bottom: var(--mb-1);
}

.home__subtitle {
  font-size: var(--h3-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-4);
}

.home__img {
  width: 300px;
  justify-self: center;
  /* mix-blend-mode: color-dodge; */
}

.home__img.dark-theme {
  mix-blend-mode: multiply;
}

/*========== BUTTONS ==========*/
.button {
  display: inline-block;
  background-color: var(--first-color);
  color: #fff;
  padding: 0.2rem .5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.4rem;
  transition: 0.3s;
}

.button:hover {
  background-color: var(--first-color-alt);
}

/*========== ABOUT ==========*/
.about__data {
  text-align: center;
}

.about__description {
  margin-bottom: var(--mb-3);
}

.about__img {
  width: 280px;
  border-radius: 0.5rem;
  justify-self: center;
}

/*========== SERVICES ==========*/
/* .services__container {
  row-gap: 2.5rem;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
} */

.services__content {
  text-align: center;
}

/* .services__img {
  width: 64px;
  height: 64px;
  fill: var(--first-color);
  margin-bottom: var(--mb-2);
} */

.services__title {
  font-size: var(--h3-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-1);
}

.services__description {
  padding: 0 1.5rem;
}


/*========== MENU ==========*/
.project__container {
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
}

.menu__content {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--container-color);
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(3, 74, 40, 0.15);
  padding: 0.75rem;
}

.project__img {
  width: 100px;
  align-self: center;
  margin-bottom: var(--mb-2);
}

.project__name,
.project__price {
  font-weight: var(--font-semi-bold);
  color: var(--title-color);
}

.project__name {
  font-size: var(--normal-font-size);
}

.project__detail,
.project__price {
  font-size: var(--small-font-size);
}

.project__detail {
  margin-bottom: var(--mb-1);
}

.project__button {
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  padding: 0.2rem 0.2rem;
  border-radius: 0.5rem 0 0.5rem 0;
}

/*========== APP ==========*/
.app__data {
  text-align: center;
}

.app__description {
  margin-bottom: var(--mb-5);
}

.app__stores {
  margin-bottom: var(--mb-4);
}

.app__store {
  width: 120px;
  margin: 0 var(--mb-1);
}

.app__img {
  width: 230px;
  justify-self: center;
}

/*========== CONTACT ==========*/
/* .contact__container {
  text-align: center;
}

.contact__description {
  margin-bottom: var(--mb-3);
} */

/*========== FOOTER ==========*/
.footer__container {
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 2rem;
}

.footer__logo {
  font-size: var(--h3-font-size);
  color: var(--first-color);
  font-weight: var(--font-semi-bold);
}

.footer__description {
  display: block;
  font-size: var(--small-font-size);
  margin: 0.25rem 0 var(--mb-3);
}

.footer__social {
  font-size: 1.5rem;
  color: var(--title-color);
  margin-right: var(--mb-2);
}

.footer__title {
  font-size: var(--h2-font-size);
  color: var(--title-color);
  margin-bottom: var(--mb-2);
}

.footer__link {
  display: inline-block;
  color: var(--text-color);
  margin-bottom: var(--mb-1);
}

.footer__link:hover {
  color: var(--first-color);
}

.footer__copy {
  text-align: center;
  font-size: var(--small-font-size);
  color: var(--text-color-light);
  margin-top: 3.5rem;
}

.email-address {
  white-space: nowrap;
}


/*========== MEDIA QUERIES ==========*/
@media screen and (min-width: 576px) {
  .home__container,
  .about__container,
  .app__container {
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
  }

  .about__data,
  .about__initial,
  .app__data,
  .app__initial,
  .contact__container,
  .contact__initial {
    text-align: initial;
  }

  .about__img,
  .app__img {
    width: 380px;
    order: -1;
  }

  .contact__container {
    grid-template-columns: 1.75fr 1fr;
    align-items: center;
  }
  .contact__button {
    justify-self: center;
  }
}

@media screen and (min-width: 768px) {
  body {
    margin: 0;
  }

  .section {
    padding-top: 8rem;
  }

  .nav {
    height: calc(var(--header-height) + 1.5rem);
  }
  .nav__list {
    display: flex;
  }

  

  .nav__item {
    margin-left: var(--mb-5);
    margin-bottom: 0;
  }
  .nav__toggle {
    display: none;
  }

  /* .change-theme {
    position: initial;
    margin-left: var(--mb-2);
  } */

  /* ========== Button Dark/Light ========== */
  .change-theme {
    position: absolute;
    right: 1rem;
    top: 1.8rem;
    color: var(--text-color);
    font-size: 2rem;
    cursor: pointer;
  }

  .home__container {
    height: 100vh;
    justify-items: center;
  }

  
  .project__container {
    margin-top: var(--mb-6);
  }

  .project__container {
    grid-template-columns: repeat(3, 210px);
    column-gap: 4rem;
  }
  .menu__content {
    padding: 1.5rem;
  }
  .project__img {
    width: 130px;
  }

  .app__store {
    margin: 0 var(--mb-1) 0 0;
  }
}

@media screen and (min-width: 960px) {
  .bd-container {
    margin-left: auto;
    margin-right: auto;
  }

  .home__img {
    width: 500px;
  }

  .about__container,
  .app__container {
    column-gap: 7rem;
  }
}

/* For tall screens on mobiles y desktop*/
@media screen and (min-height: 721px) {
  .home__container {
    height: 640px;
  }
}

/* Dark Theme Styles */
body.dark-theme {
  --background-color: #1a1919;
  --text-color: #dbd4d4;
  --button-background: #f6f9f7;
  --button-text: #31eb0c;
  --body-color: var(--background-color);
}

/* Toggle button container */
.theme-toggle {
  display: flex;
  align-items: center;
  /* background-color: #1a76c1;
  border-radius: 50%;
  width: 25px;
  height: 25px; */
  font-size: x-large;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-left: 1rem;
}

/* Icon styles */
.theme-toggle-icon {
  margin: auto;
  transition: transform 0.3s;
}











/* Your CSS file */

/*========== SERVICES ==========*/
.services__container
{
  display: flexbox;
  height: max-content;
  width: max-content;

}
.service {
  
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: inherit;
  /* padding: 20px; */
  
   /* padding: 5rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* margin: 20px; */
  /* max-width: 100%;
  background-color: #f5f5f5;  */
 
}

.services__data .services__heading {
  margin: 20px;
  /* margin: 20px; */
  font-size: 28px; /* Increase the font size for the heading */
  color: #333;
  font-weight: bold;
  /* margin: 0; */
}

.services__data .services__heading br {
  display: none;
}

.services__data .service__details {
  font-size: 18px; /* Increase the font size for the details */
  color: #666;
  max-width: 50%;
  /* margin: 20px; */
  padding: 1rem;
  margin: 20px;
}

.services__img .service__image {
  margin-left: 2rem;
  margin-right: 2;
  width: 600px;
  max-width: 400px;
  height: 350px;
  border-radius: 0.5rem;
  justify-self: center;
}
/* 
.services__img .absolute {
  /* Define your absolute positioning styles here */
/* }  */
/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .service {
    flex-direction: column; /* Stack components vertically on smaller screens */
    text-align: center; /* Center text on smaller screens */
  }

  .services__data .service__details {
    font-size: 18px;
    color: #666;
    max-width: 50%;
    text-align: left; /* Default left alignment */
  }

  .services__img .service__image {
    width: 100%; /* Full width for the image on smaller screens */
  }
   /* Center BasicCard content when ImageCard comes before BasicCard */
   .service:nth-child(even) .service__data .service__details {
    text-align: center;
  }
}


.project__item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.project__content {
  flex-grow: 1;
  /* Set a maximum width or adjust as needed */
  max-width: 80%;
  /* Enable horizontal scrolling for overflow content */
  overflow-x: auto;
}


/* CSS for small screens (maximum width of 576px) */
@media screen and (max-width: 576px) {
  .nav__menu {
    position: fixed;
    top: 0; /* Change top to 0 to align the menu to the top */
    left: -100%; /* Hide the menu off-screen initially */
    width: 100%;
    height: 100%; /* Set the height to 100% to cover the entire screen */
    padding: 2rem 0; /* Adjust padding as needed */
    text-align: center;
    background-color: var(--body-color);
    transition: 0.4s;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
    border-radius: 0; /* Remove border-radius to make it rectangular */
    z-index: var(--z-fixed);
  }

  .nav__menu.active-menu {
    left: 0; /* Slide the menu in from the left */
  }

  .nav__list {
    /* Style the list to be vertical */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%; /* Ensure the list takes up the full height */
  }

  .nav__item {
    margin: 2rem 0; /* Adjust margin as needed */
  }
  
  .nav__link {
    font-size: 1.2rem; /* Adjust font size as needed */
  }
}







.custom-alert {
  background-color: red; /* Red background */
  color: white; /* White text color */
  padding: 10px; /* Add padding to the alert */
  position: fixed; /* Fixed position to make it appear at the top */
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a shadow for depth */
  /* margin-bottom: 1rem; */
}
.not__found_img
{
  margin: 5rem;
}



/* Add styles for PrivacyPolicy page */
.PrivacyPolicy-container {
  max-width: 1000px;
  margin: 5rem auto 0; /* Add margin-top of 5rem */
  padding: 20px;
  /* background-color: #fff; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.PrivacyPolicy-content {
  margin-top: 20px;
  max-height: 400px; /* Adjust the max height based on your design */
  overflow-y: auto;
}


/* Add styles for TermsAndConditions page */
.TermsAndConditions-container {
  max-width: 1000px;
  margin: 5rem auto;
  padding: 20px;
  /* background-color: #fff; */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.TermsAndConditions-content {
  margin-top: 20px;
  max-height: 400px; /* Adjust the max height based on your design */
  overflow-y: auto;
}


.service-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.card-container {
  margin: 0 40px;
  max-width: 470px; /* Adjust as needed */
}


.inline-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2; /* number of lines to show */
}


.max-lines-2 {
  max-height: 3.6em; /* Two lines with a bit of extra space */
  overflow: hidden;
  line-height: 1.8em; /* Adjust as needed based on your design */
}

/* Add these styles to your existing CSS or create a new file */
.service-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px; /* Adjust the gap between cards */
}

/* You can add additional styles for responsiveness if needed */


/* Fade-in animation */
.fade-in {
  opacity: 0;
  animation: fadeIn ease-in-out 1;
  animation-fill-mode: forwards;
  animation-duration: 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.animate
{
   /* text-transform: uppercase; */
  background-image: linear-gradient(
    -225deg,
    #231557 0%,
    #44107a 29%,
    #ff1361 67%,
    #51ff00 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  /* text-fill-color: transparent; */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  display: inline-block;
      font-size: 20px;
      text-wrap: nowrap;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}
.dynamic-text {
  display: block;
  font-size: 30px;
  white-space: pre-line; /* This allows for line breaks in the text */
  overflow: hidden;
  /* background: linear-gradient(-225deg, #fc00ff, #00dbde); */
  background: linear-gradient(-225deg, #3a1c71, #d76d77, #ffaf7b);
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 1.5s linear infinite;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


/* 
.company-name
{
  color: rgb(25, 126, 233);
  background: rgb(205, 248, 255);
  border-radius: 10px;
  text-emphasis:inherit;
  text-shadow: 5px 5px 15px rgb(7, 183, 236);
  padding-left: 1rem;
  padding-right: 1rem;
  text-wrap: nowrap;
 
} */



.progress-bar {
  height: 5px;
  width: 100%;
  background-color: #00b2fe;
  position: fixed;
  top: 0;
  left: 0;
}

.progress-bar::before {
  content: '';
  height: 100%;
  width: 0;
  background-color: #ff2600; /* Green color for the progress bar */
  transition: width 0.3s ease;
}