/*===== GOOGLE FONTS =====*/
/* @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"); */



/*========== BASE ==========*/
*,::before,::after{
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

/*========== Variables Dark theme ==========*/

/* Dark Theme  */
 .dark-theme {
  --title-color: #F1F3F2;
  --text-color: #C7D1CC;
  --body-color: #252527;
  --container-color: #27302C;
}


body{
  margin: var(--header-height) 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  background-color: var(--body-color);
  color: var(--text-color);
  line-height: 1.6;
}

h1,h2,h3,p,ul{
  margin: 0;
}

ul{
  padding: 0;
  list-style: none;
}

a{
  text-decoration: none;
}

img{
  max-width: 100%;
  height: auto;
}

/* For modern browsers */
::-webkit-scrollbar {
  width: 10px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f0f8ff; /* Light blue background for the track */
}

::-webkit-scrollbar-thumb {
  background-color: #add8e6; /* Light blue for the thumb */
  border-radius: 10px; /* Rounded corners */
  border: 2px solid #f0f8ff; /* Adds padding effect */
}

/* For Firefox */
/* scrollbar-color: #add8e6 #f0f8ff;
scrollbar-width: thin; */


/* CSS for Contact Form */
/* form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
} */

/* label {
  font-weight: bold;
} */

/* input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  margin: 8px 0;
  border: 1px solid #ccc;
  border-radius: 3px;
} */

/* textarea {
  height: 100px;
} */

button {
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 3px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}
